<script context="module" type="ts">
    export async function load({ stuff }: LoadEvent) {
        return {
            props: {
                content: stuff.page,
            },
        }
    }
</script>

<script lang="ts">
    import { components } from '_config/constants/widgets-mapping'
    import PageTemplate from '@cucumber/ui/components/templates/page.svelte'

    export let content: Page
</script>

{#if content}
    <PageTemplate {components} {content} />
{/if}
